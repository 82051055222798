export const tableFields = [
  {
    id: 0,
    name: 'name',
    label: 'Наименование этапа',
  },
  {
    id: 1,
    name: 'amount',
    label: 'Стоимость этапа',
    props: {
      width: '210px',
    }
  },
  {
    id: 2,
    name: 'start_date',
    label: 'Дата начала',
    props: {
      width: '100px',
    },
  },
  {
    id: 3,
    name: 'expiration_date_planned',
    label: 'Дата окончания (план)',
    props: {
      width: '100px',
    },
  },
  {
    id: 4,
    name: 'expiration_date_real',
    label: 'Дата окончания (факт)',
    props: {
      width: '100px',
    },
  },
  {
    id: 5,
    name: 'rationale',
    label: 'Обоснование',
  },
  {
    id: 6,
    name: 'note',
    label: 'Примечание',
    props: {
      width: '250px',
    }
  },
  {
    id: 7,
    name: 'files',
    label: 'Файлы',
    props: {
      width: '100px',
    },
  },
];
